<template>
  <b-row>
    <b-col cols="12" sm="6">
      <div class="main-label">Profile Picture</div>

      <InputUploadFile
        id="profile-image"
        label="Profile Picture"
        :img="form.picture"
        v-model="form.picture"
        :ImageName="form.picture_file_name"
        textUpload="Upload Picture"
        :dateTime="$moment()"
        @setFileName="(val) => (form.picture_file_name = val)"
        @deleteImage="deleteImage('picture')"
      />
    </b-col>
    <b-col cols="12" sm="6">
      <div class="main-label">Identification Card Picture</div>

      <InputUploadFile
        id="id-card-image"
        label="Identification Card Profile"
        :img="form.id_card_image"
        v-model="form.id_card_image"
        :ImageName="form.id_card_file_name"
        :dateTime="$moment()"
        @setFileName="(val) => (form.id_card_file_name = val)"
        @deleteImage="deleteImage('id_card_image')"
      />
    </b-col>
    <b-col cols="12" sm="6" class="mt-3">
      <InputText
        textFloat="Identification Number"
        placeholder="Identification Number"
        type="number"
        name="Identification Number"
        :oninput="'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, 13);'"
        v-model="v.form.id_card.$model"
        :v="v.form.id_card"
        :isValidate="v.form.id_card.$error"
      />
    </b-col>
    <div class="break-normal"></div>

    <b-col sm="12" md="6">
      <InputSelect
        title="Name Title"
        name="Name Title"
        :options="prefixOption"
        valueField="name"
        textField="name"
        v-model="form.prefix"
      >
        <!-- isRequired -->
        <template v-slot:option-first>
          <b-form-select-option value="" disabled
            >Please Select
          </b-form-select-option>
        </template>
      </InputSelect>
    </b-col>
    <b-col cols="12" md="6" v-if="checkOtherValue()">
      <InputText
        textFloat="Name Title"
        placeholder="Title"
        type="text"
        name="tax-id"
        v-model="form.prefix_other"
      />
    </b-col>
    <div class="break-normal" v-else></div>
    <b-col sm="12" md="4">
      <InputText
        textFloat="First Name"
        placeholder="First Name"
        type="text"
        name="first_name_th"
        v-model="form.first_name_th"
        isRequired
        :v="v.form.first_name_th"
        :isValidate="v.form.first_name_th.$error"
      />
    </b-col>
    <b-col sm="12" md="4">
      <InputText
        textFloat="Middle Name"
        placeholder="Middle Name"
        type="text"
        name="middle_name"
        v-model="form.middle_name_th"
      />
    </b-col>
    <b-col sm="12" md="4">
      <InputText
        textFloat="Surname"
        placeholder="Surname"
        type="text"
        name="last_name_th"
        v-model="form.last_name_th"
        isRequired
        :v="v.form.last_name_th"
        :isValidate="v.form.last_name_th.$error"
      />
    </b-col>
    <b-col sm="12" md="4">
      <InputText
        textFloat="First Name (EN)"
        placeholder="First Name (EN)"
        type="text"
        name="first_name_en"
        v-model="form.first_name_en"
      />
    </b-col>
    <b-col sm="12" md="4">
      <InputText
        textFloat="Middle Name (EN)"
        placeholder="Middle Name (EN)"
        type="text"
        name="middle_name"
        v-model="form.middle_name_en"
      />
    </b-col>
    <b-col sm="12" md="4">
      <InputText
        textFloat="Surname (EN)"
        placeholder="Surname (EN)"
        type="text"
        name="last_name_en"
        v-model="form.last_name_en"
      />
    </b-col>

    <b-col cols="6" class="mb-3">
      <label class="main-label">Birthday </label>
      <div class="input-container">
        <datetime
          type="date"
          :input-style="styleDatetime"
          v-model="form.birthday"
          placeholder="DD/MM/YYYY"
          format="dd/MM/yyyy"
          value-zone="Asia/Bangkok"
          ref="birthdayDate"
        >
        </datetime>
        <div
          :class="'icon-primary text-right'"
          @click="$refs.birthdayDate.isOpen = true"
        >
          <font-awesome-icon
            icon="calendar-alt"
            :class="'pointer color-primary'"
            color="#B41BB4"
          />
        </div>
      </div>
    </b-col>
    <b-col cols="12" :sm="checkOtherGenderValue() ? 3 : 6">
      <InputSelect
        title="Gender"
        name="Gender"
        :options="genderChoice"
        v-model="form.gender"
        valueField="name"
        textField="name"
        @onDataChange="handleChange"
      >
        <!-- isRequired -->
        <template v-slot:option-first>
          <b-form-select-option value="" disabled
            >Please Select
          </b-form-select-option>
        </template>
      </InputSelect>
    </b-col>
    <b-col cols="12" md="3" v-if="checkOtherGenderValue()">
      <InputText
        textFloat="Gender"
        placeholder="Gender"
        type="text"
        name="Gender-id"
        v-model="form.gender_other"
      />
    </b-col>
    <b-col cols="12">
      <InputText
        textFloat="Email"
        placeholder="Email"
        type="text"
        name="email"
        v-model="v.form.email.$model"
        :v="v.form.email"
        :isValidate="v.form.email.$error"
        :disabled="ticket"
      />
    </b-col>
    <b-col cols="6">
      <InputTelephoneWithCountry
        textFloat="Primary Telephone."
        placeholder="Primary Telephone."
        :country="form.telephone_country_id"
        @set="(val) => (form.telephone_country_id = val)"
        v-model="form.telephone"
        isRequired
        :v="v.form.telephone"
        :isValidate="v.form.telephone.$error"
      />
    </b-col>
    <b-col cols="6">
      <InputTag v-model="optionalTelephone" :limit="parseInt(limit)" />
    </b-col>
    <b-col cols="12"> <div class="label-header">Social Media</div></b-col>
    <template v-for="$social of socialList">
      <b-col cols="12" md="6" :key="$social.id">
        <InputText
          :textFloat="$social.name"
          :placeholder="$social.name"
          type="text"
          :name="$social.name"
          v-model="$social.value"
          :oninput="'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, 30);'"
        />
      </b-col>
    </template>
  </b-row>
</template>

<script>
import UploadFile from "@/components/inputs/UploadFile";
import InputText from "@/components/inputs/InputText";
import InputSelect from "@/components/inputs/InputSelect";
import MultipleRadioButton from "@/components/inputs/MultipleRadioButton";
import InputUploadFile from "@/components/inputs/InputUploadFile";
export default {
  components: {
    UploadFile,
    InputText,
    InputSelect,
    MultipleRadioButton,
    InputUploadFile,
  },
  props: {
    formMain: {
      required: false,
      type: Object,
    },
    memberLevelList: {
      required: false,
      type: Array,
    },
    FieldList: {
      required: false,
      type: Array,
    },
    socialList: {
      required: false,
      type: Array,
      default: () => [],
    },
    v: {
      required: false,
      type: Object,
    },
    isLoadingUpdateProfile: {
      required: false,
      type: Boolean,
    },
    isConsentList: {
      required: false,
      type: Array,
    },
    limit: { required: false },
    ticket: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      isShowSidebar: false,
      image: "",
      acceptType: "image/jpeg, image/png",
      isLoadingImage: false,
      styleDatetime: {
        width: "100%",
        border: "none",
      },
      isLoadingImage: false,
      isDisable: false,
      prefixOption: [],
      genderChoice: [],
      optionalTelephone: [],
      errorOptional: false,
      form: {
        picture: "",
      },
      customerTypeList: [],
    };
  },
  mounted() {},
  computed: {},
  methods: {
    async setFields() {
      var check;

      if (this.FieldList.length > 0) {
        const gender = this.FieldList.find(
          (el) => el.field_profile_type_id == 21
        );
        const prefix = this.FieldList.find(
          (el) => el.field_profile_type_id == 2
        );

        this.genderChoice = gender.field_choices.filter(
          (el) => el.is_display == 1
        );

        const isPrefixDisplay = prefix.field_choices.filter(
          (el) => el.is_display == 1
        );
        this.prefixOption = isPrefixDisplay.map((el) => {
          return { ...el, name: el.name };
        });
      }
    },

    async show() {
      this.form = this.formMain;
      this.optionalTelephone = this.form.optional_telephone
        ? this.form.optional_telephone.split(",").filter((el) => el)
        : [];

      await this.setFields();

      this.isShowSidebar = true;
    },
    hide() {
      this.isShowSidebar = false;
    },

    onImageChange(img) {
      this.isLoadingImage = true;
      this.isDisable = true;
      var reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = async () => {
        this.images = await this.saveImagetoDb(reader.result);
        this.isLoadingImage = false;
        this.isDisable = false;
        this.form.picture = this.images;
      };
    },
    saveImagetoDb: async function (img) {
      var form = {
        Base64String: img,
      };
      await this.$store.dispatch("ActionUploadImage", form);
      var data = await this.$store.state.moduleConnectAPI.stateUploadImage;
      if (data.result == 1) {
        return data.detail;
      }
    },

    deleteImage(key) {
      this.form[key] = "";
    },
    selectMemberLevel(value) {
      this.form.member_level_id = value;
    },
    submitAction() {
      this.v.form.$touch();

      if (this.v.form.$error) {
        return;
      }

      this.updateProfileDetail();
      this.hide();
    },
    async updateProfileDetail() {
      this.$bus.$emit("showLoading");
      this.form.expire_date = this.form.expire_date
        ? this.form.expire_date
        : null;
      this.form.optional_telephone = this.optionalTelephone
        ? this.optionalTelephone.join(",")
        : "";
      this.form.birthday = this.form.birthday
        ? this.$moment(this.form.birthday).format("YYYY-MM-DD")
        : "";

      this.form.social = this.socialList.map((el) => {
        return {
          id: 0,
          social_media_type_id: el.id,
          value: el.value,
        };
      });
      if (this.checkOtherValue()) this.form.prefix = this.form.prefix_other;
      if (this.checkOtherGenderValue())
        this.form.gender = this.form.gender_other;

      const result = await this.axios.post("/customer", this.form);
      const data = result.data;

      this.$bus.$emit("hideLoading");
      if (data.result === 1) {
        if (!this.ticket)
          this.successAlert().then(() => {
            this.$router.push("/report/customer/" + data.detail.id);
          });
        return data.detail.id;
      } else {
        this.warningAlert(data.message);
        return false;
      }
    },
    handleChange(val) {
      this.form.gender = val;
      if (this.checkOtherGenderValue(val)) this.form.gender_other = "";
    },
    openModalPoint() {
      this.$emit("openModalPoint", 1);
    },
    openModalBranchPoint() {
      this.$emit("openModalPoint", 2);
    },

    validateOptional(val) {
      const isNumeric = (string) => /^[+-]?\d+(\.\d+)?$/.test(string);
      if (parseInt(this.form.limit_secondary_telephone) != 0) {
        if (
          this.optionalTelephone.length >= this.form.limit_secondary_telephone
        )
          return;
      }
      if (val.length == 10 && isNumeric(val)) {
        return val;
      }

      (this.errorOptional = true),
        setTimeout(() => {
          this.errorOptional = false;
        }, 3000);
      return;
    },
    checkOtherValue() {
      let check = this.prefixOption.find((el) => el.name == this.form.prefix);

      return check && check.choice_type_id == 3;
    },
    checkOtherGenderValue(val = this.form.gender) {
      let check = this.genderChoice.find((el) => el.name == val);

      return check && check.choice_type_id == 3;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .b-sidebar {
  width: 400px;
}
.header-filter {
  background-color: var(--theme-secondary-color);
  padding: 15px 0;
}
.text-filter {
  font-weight: 600;
  font-size: 17px;
}
.panel-bg-file-img {
  width: 50%;
  padding-bottom: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  border: 2px dashed #bfbfbf;
  margin-bottom: 15px;
  position: relative;
}

.icon-primary {
  flex: 1 1 auto;
  cursor: text;
}
.title-label {
  color: #16274a;
  font-size: 16px;
  margin-bottom: 2px;
  font-weight: bold;
}
::v-deep .rounded-pill {
  border-radius: 0px !important;
}
::v-deep .btn-main,
.btn-mains button {
  width: 100% !important;
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
}
.consent-allow {
  color: #06c306;
}
.consent-cancel {
  color: red;
}
.f-right {
  float: right;
}
.point-box {
  margin: 0px;
  padding: 10px 5px;
  background-color: var(--theme-secondary-color);
}
.icon-to-point {
  background-color: var(--primary-color);
  color: #fff;
  border: none;
  margin-left: 5px;
  font-size: 10px;
  border-radius: 3px;
  padding: 3px 9px;
}
.title-point {
  color: gray;
}
.title-total-point {
  font-weight: 600;
  font-size: 17px;
}
</style>
